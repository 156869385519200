module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"6e707a123f341bcec77e7202d5ffb1e0","enableOnDevMode":false,"pageViews":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"phonetonote","short_name":"ptn","start_url":"/","background_color":"#d8ece6","theme_color":"#236655","display":"standalone","icons":[{"src":"/images/icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/images/icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-clerk/gatsby-browser.js'),
      options: {"plugins":[],"frontendApi":"clerk.phonetonote.com","frontendApiKey":"live_NZowU0MftHPRdIYX7xGvfVgS1dcelZ47yH"},
    }]
